import './LandingFindMyPlan.scss';
import '../../Landing.scss';

import { Button, Icon } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '../../../../analytics';
import headerImage from '../../../../assets/phone-bubbles.svg';
import { useNavigate } from '../../../../components/Link';

const className = 'c-LandingFindMyPlan';

export const LandingFindMyPlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const analyticsContext = useAnalytics();

  const handleTryItOutClick = () => {
    navigate('/find-my-plan');
    const trackingData = {
      element_id: 'btn_findmyplan_engagement',
      element_text: 'Try it out',
    };

    analyticsContext?.trackClickAction(trackingData);
  };

  return (
    <div className={`p-Landing__page-content-wide`}>
      <article className={className}>
        <div className={`${className}__image-container`}>
          <img src={headerImage} role="presentation" />
        </div>

        <div className={`${className}__content`}>
          <header className={`${className}__header`}>
            <p className={`${className}__header-new`}>
              {t('landing.find_my_plan.new', 'New')}
            </p>
            <h3 className={`${className}__header-title`}>
              {t('landing.find_my_plan.title', 'Find my plan')}
            </h3>
          </header>
          <div className={`${className}__text-description`}>
            <p className={`${className}__text-description-header`}>
              {t(
                'landing.find_my_plan.description.title',
                'Our smart tool helps you:',
              )}
            </p>
            <ul className={`${className}__text-description-list`}>
              <li>
                <Icon name="check" />
                {t(
                  'landing.find_my_plan.description.first',
                  'Find the right plan for your trip.',
                )}
              </li>
              <li>
                <Icon name="check" />
                {t(
                  'landing.find_my_plan.description.second',
                  'Avoid overpaying for data.',
                )}
              </li>
              <li>
                <Icon name="check" />
                {t(
                  'landing.find_my_plan.description.third',
                  'Stay connected wherever you go.',
                )}
              </li>
            </ul>
          </div>
          <div className={`${className}__text-button`}>
            <Button
              fluid
              style={{ backgroundColor: 'var(--color-secondary)' }}
              onClick={() => handleTryItOutClick()}
            >
              {t('landing.find_my_plan.button', 'Try it out')}
            </Button>
          </div>
        </div>
      </article>
    </div>
  );
};
